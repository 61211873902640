// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contributors-js": () => import("./../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-equipment-and-software-js": () => import("./../src/pages/equipment-and-software.js" /* webpackChunkName: "component---src-pages-equipment-and-software-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-schedule-js": () => import("./../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-stream-notes-js": () => import("./../src/pages/stream-notes.js" /* webpackChunkName: "component---src-pages-stream-notes-js" */),
  "component---src-templates-equipment-detail-template-js": () => import("./../src/templates/equipment-detail-template.js" /* webpackChunkName: "component---src-templates-equipment-detail-template-js" */),
  "component---src-templates-projects-detail-template-js": () => import("./../src/templates/projects-detail-template.js" /* webpackChunkName: "component---src-templates-projects-detail-template-js" */),
  "component---src-templates-stream-notes-template-js": () => import("./../src/templates/stream-notes-template.js" /* webpackChunkName: "component---src-templates-stream-notes-template-js" */)
}

